import React, { useEffect, useState } from "react";
import { Form, Select, Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { api_request } from "../../Store/Action/CommonAction";
import { method_post } from "../../Config/config";
import {
  CHART1_ERROR,
  CHART1_LOADING,
  CHART1_SUCCESS,
  CHART2_ERROR,
  CHART2_LOADING,
  CHART2_SUCCESS,
  CHART3_ERROR,
  CHART3_LOADING,
  CHART3_SUCCESS,
  CHART4_ERROR,
  CHART4_LOADING,
  CHART4_SUCCESS,
  CHART5_ERROR,
  CHART5_LOADING,
  CHART5_SUCCESS,
  CHART6_ERROR,
  CHART6_LOADING,
  CHART6_SUCCESS,
  CHART7_ERROR,
  CHART7_LOADING,
  CHART7_SUCCESS,
  FETCH_LOCATION_ERROR,
  FETCH_LOCATION_LOADING,
  FETCH_LOCATION_SUCCESS,
  FETCH_SKILL_ERROR,
  FETCH_SKILL_LOADING,
  FETCH_SKILL_SUCCESS,
  FETCH_VALID_INPUT_ERROR,
  FETCH_VALID_INPUT_LOADING,
  FETCH_VALID_INPUT_SUCCESS,
} from "../../Store/Types/SalaryDashBoardReducerTypes";
import { useApplicationContext } from "../../Context/app-context";
import { AppDispatch } from "../../Store/Types/CommonTypes";

const { Option } = Select;

interface PaytrendInputProps {
  setShowMobileMenu: (value: boolean) => void;
}

const PaytrendInput: React.FC<PaytrendInputProps> = ({ setShowMobileMenu }) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch<AppDispatch>();
  const { setSelectedJobRole } = useApplicationContext();

  const [experienceOptions, setExperienceOptions] = useState<any[]>([]);
  const [locationOptions, setLocationOptions] = useState<any[]>([]);
  const [industryOptions, setIndustryOptions] = useState<any[]>([]);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [selectedExp, setSelectedExp] = useState(null);
  const [selectedLoc, setSelectedLoc] = useState(null);
  //eslint-disable-next-line
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(null);
  const [alertModalVisible, setAlertModalVisible] = useState(false);

  // Selectors to access Redux state
  const roles = useSelector((state: any) => state.salaryDashboard.role);
  const departments = useSelector(
    (state: any) => state.salaryDashboard.department
  );
  const validInputs = useSelector(
    (state: any) => state.salaryDashboard.validInput
  );
  const validInputsLoading = useSelector(
    (state: any) => state.salaryDashboard.validInputLoading
  );
  let skills = useSelector((state: any) => state.salaryDashboard.skill) || [];

  const skillsLoading = useSelector(
    (state: any) => state.salaryDashboard.skillLoading
  );
  const skillsSuccess = useSelector(
    (state: any) => state.salaryDashboard.skillSuccess
  );

  const rolesLoading = useSelector(
    (state: any) => state.salaryDashboard.roleLoading
  );

  const accessToken = localStorage.getItem("accessToken");

  let updatedURL = window.location.href;

  const onFinish = (values: any) => {
    setSelectedJobRole(values?.jobTitle);
    setShowMobileMenu(false);

    // Check if userType is "user" and accessBoolean is false
    // if (userType === "user" && accessBoolean === "false") {
    //   setAlertModalVisible(true); // Show the modal
    //   return; // Return from the function
    // } else {
    //   // Update accessBoolean parameter in the URL to false
    //   urlParams.set("access", "false");
    //   updatedURL = `${window.location.origin}${
    //     window.location.pathname
    //   }?${urlParams.toString()}`;
    // }

    // Redirect to the updated URL
    window.history.replaceState({ path: updatedURL }, "", updatedURL);

    dispatch(
      api_request(
        method_post,
        "/api/pay-pulse/chart1",
        values,
        CHART1_LOADING,
        CHART1_SUCCESS,
        CHART1_ERROR,
        accessToken
      )
    );

    dispatch(
      api_request(
        method_post,
        "/api/pay-pulse/chart2",
        values,
        CHART2_LOADING,
        CHART2_SUCCESS,
        CHART2_ERROR,
        accessToken
      )
    );

    dispatch(
      api_request(
        method_post,
        "/api/pay-pulse/chart3",
        values,
        CHART3_LOADING,
        CHART3_SUCCESS,
        CHART3_ERROR,
        accessToken
      )
    );

    dispatch(
      api_request(
        method_post,
        "/api/pay-pulse/chart4",
        values,
        CHART4_LOADING,
        CHART4_SUCCESS,
        CHART4_ERROR,
        accessToken
      )
    );
    dispatch(
      api_request(
        method_post,
        "/api/pay-pulse/chart5",
        values,
        CHART5_LOADING,
        CHART5_SUCCESS,
        CHART5_ERROR,
        accessToken
      )
    );
    dispatch(
      api_request(
        method_post,
        "/api/pay-pulse/chart6",
        values,
        CHART6_LOADING,
        CHART6_SUCCESS,
        CHART6_ERROR,
        accessToken
      )
    );
    dispatch(
      api_request(
        method_post,
        "/api/pay-pulse/chart7",
        values,
        CHART7_LOADING,
        CHART7_SUCCESS,
        CHART7_ERROR,
        accessToken
      )
    );
  };

  useEffect(() => {
    if (selectedRoleId) {
      dispatch(
        api_request(
          method_post,
          "/api/pay-pulse/skills",
          { job_id: selectedRoleId },
          FETCH_SKILL_LOADING,
          FETCH_SKILL_SUCCESS,
          FETCH_SKILL_ERROR,
          accessToken
        )
      );
    }
    dispatch(
      api_request(
        method_post,
        "/api/pay-pulse/valid-inputs",
        { roleId: selectedRoleId },
        FETCH_VALID_INPUT_LOADING,
        FETCH_VALID_INPUT_SUCCESS,
        FETCH_VALID_INPUT_ERROR,
        accessToken
      )
    );
  }, [selectedRoleId, dispatch, accessToken]);

  const handleJobTitleChange = (value: string) => {
    // Find the selected role object from the roles array

    const selectedRole = roles.find((role: any) => role.role === value);
    if (selectedRole) {
      setSelectedRoleId(selectedRole.id); // Set the selected role ID
    }

    form.setFieldsValue({
      skills: [],
      experience: undefined,
      locations: undefined,
    }); // Reset selected skills
    skills = [];
  };

  const handleExperienceChange = (value: any) => {
    // Find the selected role object from the roles array

    const filtered = validInputs.result.filter(
      (item: any) => item.experience === value
    );

    const distinctLocations = getDistinctLocations(filtered);

    setLocationOptions(distinctLocations);

    setSelectedExp(value);

    form.setFieldsValue({
      locations: undefined,
      industry: undefined,
    }); // Reset selected skills
  };

  const handleDepartmentChange = (value: any, option: any) => {
    setSelectedDepartmentId(option.key);
    form.setFieldsValue({ jobTitle: undefined }); // Reset selected skills
  };
  const handleLocationChange = (value: any) => {
    setSelectedLoc(value);

    form.setFieldsValue({
      industry: undefined,
    }); // Reset selected skills
  };
  // You can access the industry id using option.key}

  useEffect(() => {
    if (selectedRoleId) {
      dispatch(
        api_request(
          method_post,
          "/api/pay-pulse/locations",
          {
            roleId: selectedRoleId,
          },
          FETCH_LOCATION_LOADING,
          FETCH_LOCATION_SUCCESS,
          FETCH_LOCATION_ERROR,
          accessToken
        )
      );
    }
  }, [dispatch, accessToken, selectedRoleId]);

  const getDistinctExperiences = (data: any) => {
    const distinctExperiences = new Set();
    data.forEach((entry: any) => {
      distinctExperiences.add(entry.experience);
    });
    return Array.from(distinctExperiences);
  };

  const getDistinctLocations = (data: any) => {
    const distinctLocations = new Set();
    data.forEach((entry: any) => {
      distinctLocations.add(entry.location);
    });
    return Array.from(distinctLocations);
  };

  const getDistinctIndustries = (data: any) => {
    const distinctIndustries = new Set();
    data.forEach((entry: any) => {
      distinctIndustries.add(entry.industry);
    });
    return Array.from(distinctIndustries);
  };

  useEffect(() => {
    if (selectedRoleId && validInputs) {
      const distinctExperiences = getDistinctExperiences(validInputs.result);
      setExperienceOptions(distinctExperiences);
    }
  }, [selectedRoleId, validInputs]);

  useEffect(() => {
    if (selectedRoleId && validInputs && selectedExp) {
      if (selectedLoc) {
        const filteredByExp = validInputs.industry_result.filter(
          (item: any) => item.experience === selectedExp
        );

        const filteredByLoc = filteredByExp.filter(
          (item: any) => item.location === selectedLoc
        );
        const distinctIndustries = getDistinctIndustries(filteredByLoc);
        setIndustryOptions(distinctIndustries);
      } else {
        const filteredByExp = validInputs.industry_result.filter(
          (item: any) => item.experience === selectedExp
        );

        const distinctIndustries = getDistinctIndustries(filteredByExp);
        setIndustryOptions(distinctIndustries);
      }
    }
  }, [selectedRoleId, validInputs, selectedExp, selectedLoc]);

  return (
    <>
      <Form onFinish={onFinish} form={form} layout="vertical" className="form">
        <Form.Item
          name="jobTitle"
          label="Job Title"
          rules={[{ required: true, message: "Please select a job title!" }]}
        >
          <Select
            showSearch
            placeholder="Select"
            optionFilterProp="children"
            filterOption={(input, option) => {
              const children = option?.children as unknown as string;
              return children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            onChange={handleJobTitleChange}
            loading={rolesLoading}
            className="primary-input"
          >
            {roles
              ?.filter((role: any) => {
                // Define the allowed roles
                const allowedRoles = [
                  "software developer",
                  "back end developer",
                  "architect",
                ];

                // Check if the current role is one of the allowed roles
                return allowedRoles.includes(role.role.toLowerCase());
              })
              .sort((a: any, b: any) => {
                const roleA = a.role.toLowerCase();
                const roleB = b.role.toLowerCase();

                if (roleA < roleB) {
                  return -1;
                }
                if (roleA > roleB) {
                  return 1;
                }
                return 0;
              })
              .map((role: any) => {
                const capitalizedRole =
                  role.role.charAt(0).toUpperCase() + role.role.slice(1);
                return (
                  <Option key={role.id} value={role.role}>
                    {capitalizedRole}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item
          name="experience"
          label="Experience (year)"
          rules={
            [
              // { required: true, message: "Please select a experience value!" },
            ]
          }
        >
          <Select
            showSearch
            placeholder="Select"
            optionFilterProp="children"
            onChange={handleExperienceChange} // Call the onChange handler
            loading={validInputsLoading}
            className="primary-input"
            disabled={!selectedRoleId}
          >
            {experienceOptions
              ?.sort((a, b) => a - b)
              .map((exp) => (
                <Option key={exp} value={exp}>
                  {exp}
                </Option>
              ))}
          </Select>
          {/* <InputNumber
            className="primary-input"
            placeholder="Enter"
            style={{ width: "100%" }}
            min={1}
            max={20}
            disabled={!selectedRoleId}
          /> */}
        </Form.Item>
        <Form.Item name="locations" label="Location">
          <Select
            showSearch
            placeholder="Select"
            optionFilterProp="children"
            filterOption={(input, option) => {
              const children = option?.children as unknown as string;
              return children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            className="primary-input"
            mode="multiple" // Enable multiple selections
            disabled={!(selectedRoleId && selectedExp)}
            onChange={handleLocationChange}
          >
            {locationOptions
              ?.sort((a, b) => a - b)
              ?.map((location) => (
                <Option key={location} value={location}>
                  {location}
                </Option>
              ))}
            {/* Add more cities as needed */}
          </Select>
        </Form.Item>

        <Form.Item name="skills" label="Skills">
          <Select
            mode="tags"
            maxCount={3}
            placeholder="Select"
            style={{ width: "100%", minHeight: "40px" }}
            disabled={!selectedRoleId}
            loading={skillsLoading}
            className="primary-input"
            dropdownClassName="custom-dropdown"
          >
            {skillsSuccess &&
              skills
                ?.sort((a: any, b: any) => {
                  const skillA = a.skill.toLowerCase();
                  const skillB = b.skill.toLowerCase();

                  if (skillA < skillB) {
                    return -1;
                  }
                  if (skillA > skillB) {
                    return 1;
                  }
                  return 0;
                })
                ?.map((skill: any) => (
                  <Option key={skill.id} value={skill.skill}>
                    {skill.skill}
                  </Option>
                ))}
            {/* Add more skills as needed */}
          </Select>
        </Form.Item>

        {industryOptions.length > 0 && (
          <Form.Item name="industry" label="Industry">
            <Select
              showSearch
              placeholder="Select "
              className="primary-input"
              optionFilterProp="children"
              filterOption={(input, option) => {
                const children = option?.children as unknown as string;
                return (
                  children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              }}
              disabled={!(selectedRoleId && selectedExp)}
            >
              {industryOptions
                ?.sort((a, b) => a - b)
                ?.map((industry) => (
                  <Option key={industry} value={industry}>
                    {industry}
                  </Option>
                ))}
              {/* Add more industries as needed */}
            </Select>
          </Form.Item>
        )}

        <Form.Item name="department" label="Department" className="d-none">
          <Select
            placeholder="Select "
            className="primary-input"
            onChange={handleDepartmentChange}
          >
            {departments
              ?.sort((a: any, b: any) => {
                const departmentA = a.department.toLowerCase();
                const departmentB = b.department.toLowerCase();
                if (departmentA < departmentB) {
                  return -1;
                }
                if (departmentA > departmentB) {
                  return 1;
                }
                return 0;
              })
              ?.map((department: any) => (
                <Option key={department.id} value={department.department}>
                  {department.department}
                </Option>
              ))}
            {/* Add more departments as needed */}
          </Select>
        </Form.Item>

        <div className="row ">
          <Form.Item className="col-7 pr-2">
            <Button
              type="primary"
              className="btn-primary"
              htmlType="submit"
              style={{ width: "100%" }}
            >
              Submit
            </Button>
          </Form.Item>

          <Form.Item className="col-5 pl-2">
            <Button
              className="btn-primary"
              onClick={(e) => {
                e.preventDefault();
                form.resetFields();
              }}
              style={{ width: "100%" }}
            >
              Reset
            </Button>
          </Form.Item>
        </div>
      </Form>
      <Modal
        visible={alertModalVisible}
        centered
        footer={null}
        onCancel={() => setAlertModalVisible(false)}
      >
        <div style={{ textAlign: "center" }}>
          <img
            width={60}
            src="https://res.cloudinary.com/dsw1ubwyh/image/upload/v1713340051/warning_hrr1ip.png"
            alt=""
            className="mb-3"
          />
          <h3>Your free trial has ended</h3>
          <p>
            Please contact us to discuss our packages and upgrade your account.
          </p>
          <Button
            type="primary"
            className="btn-primary"
            onClick={() =>
              (window.location.href = "https://www.humello.com/contact")
            }
          >
            Contact now
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default PaytrendInput;
